<template>
  <div class="p-1 mt-4">
    <b-overlay :show="showOverlay">
        <b-form @submit="onSubmit" autocomplete="off">
          <b-form-group label-cols-sm="12" label-cols-md="3" :label="$t('pages.langauge')" label-for="language">
            <b-overlay :show="languagesOverlay" spinner-small>
              <b-form-input @change="changeLang" id="language" list="language-list" v-model="langName" :placeholder="$t('users.choose-language')" autocomplete="off"></b-form-input>
              <datalist id="language-list">
                <option v-for="lang in languages" :key="lang.id">{{ lang.name }}</option>
              </datalist>
            </b-overlay>
          </b-form-group>

          <b-form-group label-cols-sm="12" label-cols-md="3" :label="$t('pages.title')" label-for="title">
            <b-form-input v-model="page.title" @blur="getUrl" :placeholder="$t('pages.enter-title')"></b-form-input>
          </b-form-group>

          <b-form-group label-cols-sm="12" label-cols-md="3" :label="$t('pages.url')" label-for="url" prepend="@">
            <b-input-group :prepend="'/' + pageLang.shortName + '/'">
              <template v-if="page.url">
                <b-form-input v-model="page.url" :placeholder="$t('pages.enter-url')"></b-form-input>
              </template>
              <template v-else>
                <b-form-input v-model="pageUrl" :placeholder="$t('pages.enter-url')"></b-form-input>
              </template>
            </b-input-group>
          </b-form-group>

          <b-form-group label-cols-sm="12" label-cols-md="3" :label="$t('pages.images')" label-for="images">
            <div class="input-group px-2 py-3">
                  <div v-if="images.length > 0">
                    <b-overlay :show="uploadOverlay">
                      <b-row>
                        <b-col md="12">
                          <b-row>
                              <b-col cols="6" md="4" v-for="(image, index) in images" :key="index">
                                <b-img thumbnail fluid :src="image" :alt="'Image ' + index" class="m-2 posterImages"></b-img>
                                <div class="draftButton">
                                  <BIconTrashFill font-scale="1" variant="danger" @click="removeImage(index)"/>
                                </div>
                              </b-col>
                            </b-row>
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </div>
                  <label for="file-input">
                    <BIconPlusSquare font-scale="2" variant="info" class="pt-2"></BIconPlusSquare> {{ $t('pages.upload-images') }}
                  </label>
                  <input id="file-input" type="file"  @change="uploadFile" style="display: none;" multiple/>
              </div>
          </b-form-group>

          <b-form-group label-cols-sm="12" label-cols-md="3" :label="$t('pages.content')" label-for="content">
            <editor :init="tinyDesc" v-model="page.content"></editor>
            <small v-if="$root.lang.IANA === 'sr-Latn'">Napomena: ukoliko unosite reči na stranim jezicima (latiničnim) označite ih i kliknite ikonicu LAT, kako bi se ispravno prikazale na ćiriličnoj verziji.</small>
          </b-form-group>

          <b-form-group label-cols-sm="12" label-cols-md="3" :label="$t('pages.published')" label-for="published">
            <b-form-checkbox id="published" v-model="page.published" name="check-button" size="lg" switch></b-form-checkbox>
          </b-form-group>

          <b-form-group label-cols-sm="12" label-cols-md="3" :label="$t('pages.transPages')" label-for="transPages">
            <b-row v-for="lang in languages" :key="lang.id" class="mb-4">
              <template v-if="lang.name !== langName">
                <b-col sm="2">
                  <b-avatar :src="lang.icon" size="30px" /> 
                </b-col>
                <b-col sm="2">
                <span class="mr-auto">{{ lang.name }}</span>
                </b-col>
                <b-col>
                <b-form-select v-model="selectedPagesIndex[lang.shortName]" @change="changeTransPage($event)" class="selectedPage">
                  <b-form-select-option v-for="p in allPages[lang.shortName]" :key="p.id" :value="p.id">
                  {{p.title}}
                  </b-form-select-option>
                </b-form-select>
                </b-col>
              </template>
            </b-row>
          </b-form-group>


          <b-form-group label-cols-sm="12" label-cols-md="3" v-if="page.language">
            <b-row>
                <b-col class="d-flex justify-content-between">
                  <b-overlay :show="saveSpinner" spinner-small spinner-variant="primary" opacity="0.5" class="d-inline-block">
                    <b-button type="submit" variant="primary">{{ $t('pages.save-page')}}</b-button>
                  </b-overlay>
                  <div v-if="!page.deleted">
                    <b-overlay :show="deleteSpinner" spinner-small spinner-variant="danger" opacity="0.5" class="pl-5 d-inline-block">
                      <b-button type="button" @click="deletePage" variant="outline-danger">{{ $t('pages.delete-page')}}</b-button>
                    </b-overlay>
                  </div>
                  <div v-else>
                    <b-overlay :show="deleteSpinner" spinner-small spinner-variant="warning" opacity="0.5" class="pl-5 d-inline-block">
                      <b-button type="button" @click="restorePage" variant="outline-warning">{{ $t('pages.restore-page')}}</b-button>
                    </b-overlay>
                  </div>
                </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label-cols-sm="12" label-cols-md="3" v-else>
            <b-overlay :show="saveSpinner" spinner-small spinner-variant="primary" opacity="0.5" class="d-inline-block">
              <b-button type="submit" variant="primary">{{ $t('pages.add-page')}}</b-button>
            </b-overlay>
          </b-form-group>
        </b-form>
    </b-overlay>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { BImg, BIconPlusSquare, BFormCheckbox, BInputGroup, BIconTrashFill, BAvatar, BFormSelect, BFormSelectOption } from 'bootstrap-vue'
export default {
  name: "PageForm",
  components: { BImg, Editor, BFormCheckbox, BInputGroup, BIconPlusSquare, BIconTrashFill, BAvatar, BFormSelect, BFormSelectOption },
  data: function() {
    return {
      page: {},
      showOverlay: false,
      tinyDesc: this.tinyInit(650),
      languages: [],
      languagesOverlay: true,
      langName: '',
      pageUrl: "",
      pageLang : {},
      user : {},
      saveSpinner: false,
      deleteSpinner: false,
      imagesState: null,
      images: [],
      uploadOverlay: false,
      allPages: [],
      selectedPages: [],
      selectedPagesIndex: []
    }
  },

  created: async function() {
    this.user = this.$root.user;
    
    // Jezici za padajući meni
    await this.$http.get("/cms/api/languages/enabled/", { headers: {Authorization: this.getAuthData().accessToken }})
      .then(response => {
        this.languages = response.data;
      }).catch(error => {
        this.handleError(error);
    });

    // Ako postoji parametar pageId, onda je u pitanju editovanje stranice
    if (this.$route.params.pageId) {
      await this.$http.get("/cms/api/page/get/" + this.$route.params.pageId + "/", { headers: {Authorization: this.getAuthData().accessToken }})
        .then(r => {
          // console.log(r.data);
          this.page = r.data;
          // langName služi za detektovanje jezika
          this.langName = this.page.language.name;
          // pageLang za submit 
          this.pageLang = this.page.language;
          this.images = JSON.parse(this.page.image) === null ? [] : JSON.parse(this.page.image);
        }).catch(e => {
         this.handleError(e);
      })
    } else {
      this.pageLang = this.user.language;
      this.langName = this.user.language.name;
    }

    // Preuzmi transliterovane stdranice, selektovane
    await this.$http.get("/cms/api/page/transpages/" + this.$route.params.pageId, { headers: {Authorization: this.getAuthData().accessToken }})
      .then(r => {
        for (let i = 0; i < r.data.length; i++) {
          this.selectedPagesIndex[r.data[i].language.shortName] = r.data[i].id;
        }
        this.selectedPages = r.data;
      }).catch(e => {
        this.handleError(e);
    })

     // Stranice za padajući menu prevedene stranice
    for (let i = 0; i < this.languages.length; i++) {
      const myLang =  this.languages[i];
      if (myLang.shortName !== this.pageLang.shortName) {
        await this.$http.get("/cms/api/page/published/" + myLang.shortName, { headers: {Authorization: this.getAuthData().accessToken }})
          .then(r => {
            const myPages = [];
            for (let i = 0; i < r.data.length; i++) {
              const myPage = r.data[i];
              myPages.push(myPage);
            }
            this.allPages[myLang.shortName] = myPages;
          }).catch(e => {
            this.handleError(e);
        })
      }
    }
    this.languagesOverlay = false;
  },
  mounted() {
      
  },
  methods: {
    getUrl: function() {
      if (this.page.title.length > 0) {
        if (this.page.url) {
          this.page.url = this.generateUrl(this.page.title)
        } else {
          this.pageUrl = this.generateUrl(this.page.title);
        }
      }
    },

    imagesSelected: function(selectedFiles) {
      // console.log("Images selected");
      //console.log(selectedFiles);
      this.images = selectedFiles;
    },

    deletePage: function() {
      this.$http.post('/cms/api/page/remove/', this.page, { headers: {Authorization: this.getAuthData().accessToken }})
        .then(r => {
          this.deleteSpinner = true;
           this.$router.push({name : 'PagesForLanguage', params :  {languageShortName: this.page.language.shortName }});
        }).catch(e => {
          this.deleteSpinner = false;
          var message = this.handleError(e);
        })
    },

    restorePage: function() {
      this.$http.post('/cms/api/page/restore/', this.page, { headers: {Authorization: this.getAuthData().accessToken }})
        .then(r => {
         this.$router.push({name : 'PagesForLanguage', params :  {languageShortName: this.page.language.shortName }});
        }).catch(e => {  })
    },

    changeLang: function() {
      if (this.langName !== "")  {
        for (var index in this.languages) {
          if (this.languages[index].name === this.langName) {
            this.pageLang = this.languages[index];
          }
        }
      } else {
        this.pageLang = this.page.language;
      }
    },

    changeTransPage() {
      const selectedPagesSelect = document.getElementsByClassName("selectedPage");
      this.selectedPages = [];
      for (const page in this.allPages) {
        const langPages = this.allPages[page];
        for (let i = 0; i < langPages.length; i++) {
          const singlePage = langPages[i];
          // console.log(singlePage);
          for (let j = 0; j < selectedPagesSelect.length; j++) {
            if (singlePage.id === parseInt(selectedPagesSelect[j].value)) {
              this.selectedPages.push(singlePage);
            }
          }
        }
      }
    },

    uploadFile: function () {
      this.uploadOverlay = true;
      const files = document.getElementById("file-input").files;
      // console.log(files);
      const data = new FormData();
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          data.append("images", files[i]);
        }
        const axiosConfig = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.getAuthData().accessToken
          }
        };
        // Upload slika
        this.$http.post("/cms/api/page/upload-images", data, axiosConfig).then(r => {
          this.images.push(...r.data);
        }).catch(e => {
          var message = this.handleError(e);
        })
        this.uploadOverlay = false;
      }
    },

    removeImage: function(index) {
      if (confirm(this.$t("Da li zaista želite da obrišete sliku?"))) {
        this.images.splice(index, 1);
      }
    },
    
    onSubmit: function(event) {
      event.preventDefault();
      this.saveSpinner = true;
      if (this.pageUrl !== "") {
        this.page.url = this.pageUrl;
      }
      // Setujemo jezik u slučaju da je promenjen
      this.page.language = this.pageLang;
      // Setujemo created by
      this.page.createdBy = this.user;
      this.page.image = JSON.stringify(this.images);
      this.page.translatedPages = this.selectedPages;
      // console.log(this.page);

      this.$http.post('/cms/api/page/save/', this.page, { headers: {Authorization: this.getAuthData().accessToken }})
        .then(r => {
          this.$router.push({name : 'PagesForLanguage', params :  {languageShortName: this.page.language.shortName }});
        }).catch(e => {
          this.handleError(e);
        })
      this.saveSpinner = false;
    }
  }
}
</script>
<style scoped>
 #addImage {padding: 10px; background-color: #fff; border: 1px solid #D9DBE1; cursor: pointer;}
.draftButton {top: .9rem; right: .9rem;}
</style>
